import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import styles from "@/styles/formulary.module.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { MdOutlineClose, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDrugDetails,
  getFormularyDataList,
  setClearDrugList,
  setUpdateFormularyData,
} from "@/store/slices/filterSlice";
import { useRouter } from "next/router";
import toast from "react-hot-toast";

const FormularyForm = () => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      year: { value: "2025", label: "2025" },
    },
  });

  let { year, drug_name, plan, tier } = watch();

  const router = useRouter();
  const dispatch = useDispatch();
  const filterConfig = useSelector((state) => state.filters);
  let { formularyData, drugList } = filterConfig;
  let { limit, page } = drugList;

  const [loading, setLoading] = useState({
    drug_name: false,
    year: false,
    plan: false,
    tier: false,
  });
  const [options, setOptions] = useState({
    drug_name: [],
    year: [],
    plan: [],
    tier: [],
  });

  const debounceTimerRef = useRef();

  const onChangeUpdate = (e) => {
    let { value, name } = e.target;
    clearTimeout(debounceTimerRef.current);

    if (typeof value === "string") {
      debounceTimerRef.current = setTimeout(() => {
        if (name === "year")
          drugSearch({
            key: "year",
            value: value,
            masterListVar: "yearList",
          });
        if (name === "drug_name")
          drugSearch({
            key: "drug_name",
            value: value,
            masterListVar: "drugNameList",
            year: year?.value,
          });
        if (name === "plan")
          drugSearch({
            key: "plan",
            value: value,
            masterListVar: "planList",
            year: year?.value,
            drug_name: drug_name?.value,
          });
        if (name === "tier")
          drugSearch({
            key: "tier",
            value: value,
            masterListVar: "tierList",
            year: year?.value,
            drug_name: drug_name?.value,
            plan: plan?.value,
          });
        else console.log("name is not valid", name);
      }, 500);
    }
  };

  useEffect(() => {
    if (formularyData?.drug_name)
      setValue("drug_name", formularyData?.drug_name);
    if (formularyData?.year) setValue("year", formularyData?.year);
    if (formularyData?.plan) setValue("plan", formularyData?.plan);
    if (formularyData?.tier) setValue("tier", formularyData?.tier);
  }, []);

  const drugSearch = (data) => {
    let { key, value, masterListVar, year, drug_name, plan } = data;

    let payload = {
      key,
      value,
      masterListVar,
    };

    if (year) payload.year = year;
    if (drug_name) payload.drug_name = drug_name;
    if (plan) payload.plan = plan;

    setLoading((prev) => ({ ...prev, [key]: true }));

    let queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    console.log("queryString: ", queryString);

    dispatch(getFormularyDataList({ query: queryString }))
      .unwrap()
      .then((res) => {
        setLoading((prev) => ({ ...prev, [key]: false }));
        if (res) {
          if (key === "plan") {
            let data = res?.list?.map((item) => ({
              value: item?.value,
              label:
                item?.value === "CSNP"
                  ? "Liberty Medicare Advantage Plan CSNP"
                  : item?.value === "ISNP"
                  ? "Liberty Medicare Advantage Nursing Home Plan - ISNP"
                  : item?.label,
            }));
            setOptions((prev) => ({ ...prev, [key]: [...new Set(data)] }));
          } else {
            setOptions((prev) => ({ ...prev, [key]: [...new Set(res.list)] }));
          }
        }
      })
      .catch((err) => {
        setLoading((prev) => ({ ...prev, [key]: false }));
      });
  };

  const onSubmit = (data) => {
    let payload = {
      drug_name: data.drug_name?.value,
      year: data.year?.value,
      plan: data.plan?.value,
      tier: data.tier?.value,
    };
    dispatch(setUpdateFormularyData(data));
    dispatch(
      getDrugDetails({
        page: 1,
        limit: 15,
        ...payload,
      }),
    ).then((res) => {
      if (res?.type === "filters/getDrugDetails/fulfilled") {
        // if (res?.payload?.data?.length) {
        router.push("/formulary/result");
        // } else {
        // toast.error("Search result not found!");
        // }
      }
    });
  };

  const onClearForm = () => {
    setOptions({ drug_name: [], year: [], plan: [], tier: [] });
    reset({
      drug_name: "",
      year: { value: "2025", label: "2025" },
      plan: "",
      tier: "",
    });
    dispatch(setUpdateFormularyData({}));
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx("row", styles.formDiv)}
      >
        <div className="col-12 col-md-6">
          <label htmlFor="year">
            Select Year*
            <span className={clsx(styles.required)}>
              {errors.year && errors.year.message}
            </span>
          </label>
          <Controller
            name="year"
            control={control}
            rules={{ required: "required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={options.year}
                className={clsx(
                  styles.inputField,
                  errors.year ? styles.error : "",
                )}
                onChange={(e) => {
                  field.onChange(e);
                }}
                onInputChange={(e) => {
                  onChangeUpdate({ target: { value: e, name: "year" } });
                }}
                onFocus={(e) => {
                  drugSearch({
                    key: "year",
                    value: e.target.value,
                    masterListVar: "yearList",
                  });
                }}
                isClearable
                isLoading={loading.year}
                components={{
                  IndicatorSeparator: () => null,
                  // IndicatorsContainer: DropdownIndicator,
                }}
              />
            )}
          />
        </div>

        <div className="col-12 col-md-6">
          <label htmlFor="drugName">Drug Name</label>
          <Controller
            name="drug_name"
            control={control}
            render={({ field }) => (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Select
                  {...field}
                  options={options.drug_name}
                  className={clsx(styles.inputField)}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onInputChange={(e) => {
                    onChangeUpdate({ target: { value: e, name: "drug_name" } });
                  }}
                  onFocus={(e) => {
                    drugSearch({
                      key: "drug_name",
                      value: e.target.value,
                      masterListVar: "drugNameList",
                      year: year?.value,
                    });
                  }}
                  placeholder=""
                  openMenuOnFocus={false}
                  openMenuOnClick={false}
                  isClearable
                  isLoading={loading.drug_name}
                  components={{
                    IndicatorSeparator: () => null,
                    IndicatorsContainer: () => null,
                    // ClearIndicator: ClearIndicator,
                  }}
                />
                {
                  // show only if the value is not empty
                  field?.value && (
                    <span
                      onClick={() => {
                        setValue("drug_name", "");
                      }}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        right: 20,
                        transform: "translateY(-50%)",
                      }}
                    >
                      <MdOutlineClose color="#a0a0a0" />
                    </span>
                  )
                }
              </div>
            )}
          />
        </div>

        <div className="col-12 col-md-6">
          <label htmlFor="plan">Select Plan</label>
          <Controller
            name="plan"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options.plan}
                className={clsx(styles.inputField)}
                onChange={(e) => {
                  field.onChange(e);
                }}
                onInputChange={(e) => {
                  onChangeUpdate({ target: { value: e, name: "plan" } });
                }}
                onFocus={(e) => {
                  drugSearch({
                    key: "plan",
                    value: e.target.value,
                    masterListVar: "planList",
                    year: year?.value,
                    drug_name: drug_name?.value,
                  });
                }}
                isClearable
                isLoading={loading.plan}
                components={{
                  IndicatorSeparator: () => null,
                  // IndicatorsContainer: DropdownIndicator,
                }}
              />
            )}
          />
        </div>

        <div className="col-12 col-md-6">
          <label htmlFor="tier">Tier (optional)</label>
          <Controller
            name="tier"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options.tier}
                className={clsx(styles.inputField)}
                onChange={(e) => {
                  field.onChange(e);
                }}
                onInputChange={(e) => {
                  onChangeUpdate({ target: { value: e, name: "tier" } });
                }}
                onFocus={(e) => {
                  drugSearch({
                    key: "tier",
                    value: e.target.value,
                    masterListVar: "tierList",
                    year: year?.value,
                    drug_name: drug_name?.value,
                    plan: plan?.value,
                  });
                }}
                isClearable
                isLoading={loading.tier}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          />
        </div>

        <div className="col-12 col-md-6">
          <div className={clsx("", styles.btnDiv)}>
            <button
              type="submit"
              className={clsx("", styles.searchBtn)}
            >
              Search
            </button>
            <button
              type="reset"
              className={clsx("", styles.resetBtn)}
              onClick={() => {
                onClearForm();
              }}
            >
              Clear Filters
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormularyForm;
