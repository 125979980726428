import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { getMethod, postMethod } from "../../utils/apiServices";

export const getMasterDataList = createAsyncThunk(
  "filters/getMasterDataList",
  async ({ key, value, masterListVar, releventQuery, searchFor = "" }) => {
    console.log("searchFor: ", searchFor);
    const response = await postMethod(
      `web-data?key=${key}&masterListVar=${masterListVar}&value=${value?.trim()}&searchFor=${searchFor}`,
      { releventQuery: releventQuery ? releventQuery : "" },
      {},
    );
    return response.data;
  },
);

export const getFormularyDataList = createAsyncThunk(
  "filters/getFormularyDataList",
  async ({ query }) => {
    const response = await getMethod(`fetchDrugDetails?${query}`);
    return response.data;
  },
);

export const getSearchProviders = createAsyncThunk(
  "filters/getSearchProvider",
  async ({ body }) => {
    const response = await postMethod("doctors/search", body, {});
    return response.data;
  },
);

export const getFacilityLocation = createAsyncThunk(
  "filters/getFacilityLocation",
  async ({ body, params }) => {
    const response = await getMethod("facilities/search", params);
    return response.data;
  },
);

export const getSingleDoctor = createAsyncThunk(
  "filters/getSingleDoctor",
  async ({ slug }) => {
    const response = await getMethod(`web-providers/${slug}`, {});
    return response.data;
  },
);
export const getSingleFacility = createAsyncThunk(
  "filters/getSingleFacility",
  async ({ slug }) => {
    const response = await getMethod(`web-facilities/${slug}`, {});
    return response.data;
  },
);

export const getSinglePharmacy = createAsyncThunk(
  "filters/getSinglePharmacy",
  async ({ slug }) => {
    const response = await getMethod(`pharmacyBySlug/${slug}`, {});
    return response.data;
  },
);

export const postDataList = createAsyncThunk(
  "filters/postDataList",
  async ({ page, limit, modifledObject }) => {
    const response = await postMethod(
      `apply-filter?page=${page}&limit=${limit}`,
      modifledObject,
      {},
    );
    return response.data;
  },
);

export const getPharmacyDataList = createAsyncThunk(
  "filters/getPharmacyDataList",
  async ({ county = "", state = "", city = "", zipcode = "", page, limit }) => {
    const response = await getMethod(
      `search-pharmacies?county=${county}&state=${state}&city=${city}&zipcode=${zipcode}&page=${page}&limit=${limit}`,
    );
    return response;
  },
);

export const getDrugDetails = createAsyncThunk(
  "filters/getDrugDetails",
  async ({ year = "", plan = "", tier = "", drug_name = "", page, limit }) => {
    const response = await getMethod(
      `/search-drugs?tier=${tier}&plan=${plan}&year=${year}&drugName=${drug_name}&page=${page}&limit=${limit}`,
    );
    return response;
  },
);

export const postPatientForm = createAsyncThunk(
  "filters/postPatientForm",
  async ({ data }) => {
    console.log("data: ", data);
    const response = await postMethod("patient-detail-form", data, {});
    return response.data;
  },
);

export const getDrugCostList = createAsyncThunk(
  "filters/getDrugCostList",
  async ({ query }) => {
    const response = await getMethod(`/drugs-tier-list?${query}`);
    return response.data;
  },
);

const initformValue = {
  selectFor: "",
  selectCSCType: "",
  zipcode: "",
  keyword: "",
  county: "",
  state: "",
  city: "",
  hospital: "",
  specialties: "",
  gender: "",
  languages: "",
};
const initMasterData = {
  countyList: [],
  stateList: [],
  zipCodeList: [],
  cityList: [],
  hospitalList: [],
  specialtiesList: [],
  genderList: [],
  languagesList: [],
};
const initList = {
  list: [],
  total: 0,
  page: 0,
  limit: 10,
  key: "",
  selectedCard: "",
};

const drugList = {
  list: [],
  total: 0,
  page: 1,
  limit: 15,
};

const formularyData = {
  drug_name: "",
  year: "",
  plan: "",
  tier: "",
};

const drugCostData = {
  year: { value: "", label: "" },
  plan: { value: "", label: "" },
  selectedDrugs: [],
  drugList: [],
};

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    formSelected: "advancefillter",
    formFields: initformValue,
    globalLoader: false,
    masterData: initMasterData,
    formularyData,
    drugList,
    drugCostData,
    listData: initList,
    singleDoctorData: {},
  },
  reducers: {
    setFormType: (state, actions) => {
      state.formSelected = actions.payload;
    },
    setUpdateFormField: (state, action) => {
      const oldState = JSON.parse(JSON.stringify(state));
      var { formFields } = oldState;
      const { key, value } = action.payload;
      formFields[key] = value;
      state.formFields = formFields;
    },
    setUpdateAllFormField: (state, action) => {
      state.formFields = action.payload;
    },
    setClearAllFormData: (state, action) => {
      state.formFields = initformValue;
    },
    setUpdateGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    },
    setUpdatePushList: (state, action) => {
      const { list, key, valueData } = action.payload;
      const modiedValue = [...list, { value: valueData, label: valueData }];
      state.masterData[key] = modiedValue?.length
        ? Array.from(new Set(modiedValue.map((obj) => obj.value))).map(
            (value) => modiedValue?.find((obj) => obj?.value === value),
          )
        : [];
    },
    setSelectedCardUpdate: (state, action) => {
      state.listData.selectedCard = action.payload;
    },
    setClearListData: (state, action) => {
      state.listData = initList;
    },
    setUpdateDataDoctorPage: (state, action) => {
      state.singleDoctorData = action.payload;
      state.globalLoader = false;
    },
    setUpdateFormularyData: (state, action) => {
      if (Object.keys(action?.payload)?.length) {
        state.formularyData.drug_name = action.payload.drug_name || "";
        state.formularyData.year = action.payload.year || "";
        state.formularyData.plan = action.payload.plan || "";
        state.formularyData.tier = action.payload.tier || "";
      } else {
        state.formularyData = formularyData;
      }
    },
    setClearFormularyData: (state, action) => {
      state.formularyData = formularyData;
    },

    setSelectedDrugs: (state, action) => {
      state.drugCostData.selectedDrugs = action.payload;
    },
    setDrugCostYear: (state, action) => {
      state.drugCostData.year = action.payload;
    },
    setDrugCostPlan: (state, action) => {
      state.drugCostData.plan = action.payload;
    },
    setDrugCostData: (state, action) => {
      state.drugCostData.drugList = action.payload;
    },

    setClearDrugCostData: (state, action) => {
      state.drugCostData.drugList = [];
    },

    setClearDrugList: (state, action) => {
      console.log("drugList: ", drugList);
      state.drugList = drugList;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMasterDataList.pending, (state, action) => {
        state.globalLoader = false;
        state.listData.key = action?.meta?.arg?.key
          ? action?.meta?.arg?.key
          : "";
      })
      .addCase(getMasterDataList.fulfilled, (state, action) => {
        if (action.payload) {
          state.globalLoader = false;
          state.listData.key = "";
          let { list, masterListVar } = action.payload;
          if (list?.length) {
            state.masterData[masterListVar] = list;
          }
        }
      })
      .addCase(getMasterDataList.rejected, (state, action) => {
        state.globalLoader = false;
        state.listData.key = "";
      })

      .addCase(postDataList.pending, (state, action) => {
        state.globalLoader = true;
        if (action?.meta?.arg?.page === 0) {
          (state.listData.list = []),
            (state.listData.total = 0),
            (state.listData.page = 0),
            (state.listData.limit = 10);
        }
      })
      .addCase(postDataList.fulfilled, (state, action) => {
        const {
          listData: { list },
        } = JSON.parse(JSON.stringify(state));
        state.globalLoader = false;
        if (!list?.length) {
          state.listData.list = action?.payload?.list
            ? action?.payload?.list
            : [];
        } else {
          state.listData.list = list.concat(action?.payload?.list);
        }
        (state.listData.total = action?.payload?.total
          ? action?.payload?.total
          : 0),
          (state.listData.page = action?.payload?.page
            ? action?.payload?.page
            : 0),
          (state.listData.limit = action?.payload?.limit
            ? action?.payload?.limit
            : 10);
      })
      .addCase(postDataList.rejected, (state, action) => {
        state.globalLoader = false;
      })

      .addCase(getSingleDoctor.pending, (state, action) => {
        state.globalLoader = true;
        state.singleDoctorData = {};
      })
      .addCase(getSingleDoctor.fulfilled, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = action.payload;
      })
      .addCase(getSingleDoctor.rejected, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = {};
      })

      .addCase(getSingleFacility.pending, (state, action) => {
        state.globalLoader = true;
        state.singleDoctorData = {};
      })
      .addCase(getSingleFacility.fulfilled, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = action.payload;
      })
      .addCase(getSingleFacility.rejected, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = {};
      })

      .addCase(getSinglePharmacy.pending, (state, action) => {
        state.globalLoader = true;
        state.singleDoctorData = {};
      })
      .addCase(getSinglePharmacy.fulfilled, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = action.payload;
      })
      .addCase(getSinglePharmacy.rejected, (state, action) => {
        state.globalLoader = false;
        state.singleDoctorData = {};
      })

      .addCase(getFormularyDataList.pending, (state, action) => {
        // state.globalLoader = true;
      })
      .addCase(getFormularyDataList.fulfilled, (state, action) => {
        if (action.payload) {
          state.globalLoader = false;
          state.listData.key = "";
          let { list, masterListVar } = action.payload;
          if (list?.length) {
            state.formularyData[masterListVar] = list;
          }
        }
      })
      .addCase(getFormularyDataList.rejected, (state, action) => {
        state.globalLoader = false;
      })

      .addCase(getPharmacyDataList.pending, (state, action) => {
        state.globalLoader = true;
        if (action?.meta?.arg?.page === 0) {
          (state.listData.list = []),
            (state.listData.total = 0),
            (state.listData.page = 0),
            (state.listData.limit = 10);
        }
      })
      .addCase(getPharmacyDataList.fulfilled, (state, action) => {
        const {
          listData: { list },
        } = JSON.parse(JSON.stringify(state));
        state.globalLoader = false;
        if (!list?.length) {
          state.listData.list = action?.payload?.data
            ? action?.payload?.data
            : [];
        } else {
          state.listData.list = list.concat(action?.payload?.data);
        }
        (state.listData.total = action?.payload?.total
          ? action?.payload?.total
          : 0),
          (state.listData.page = action?.payload?.page
            ? action?.payload?.page
            : 0),
          (state.listData.limit = action?.payload?.limit
            ? action?.payload?.limit
            : 10);
      })
      .addCase(getPharmacyDataList.rejected, (state, action) => {
        state.globalLoader = false;
      })

      .addCase(getDrugDetails.pending, (state, action) => {
        state.globalLoader = true;
      })
      .addCase(getDrugDetails.fulfilled, (state, action) => {
        state.globalLoader = false;
        state.drugList.list = action.payload.data;
        (state.drugList.total = action?.payload?.total
          ? action?.payload?.total
          : 0),
          (state.drugList.page = action?.payload?.page
            ? action?.payload?.page
            : 0),
          (state.drugList.limit = action?.payload?.limit
            ? action?.payload?.limit
            : 10);
      })
      .addCase(getDrugDetails.rejected, (state, action) => {
        state.globalLoader = false;
      });

    builder
      .addCase(getDrugCostList.pending, (state, action) => {
        state.globalLoader = true;
      })
      .addCase(getDrugCostList.fulfilled, (state, action) => {
        state.globalLoader = false;
        state.drugCostData.drugList = action.payload;
      })
      .addCase(getDrugCostList.rejected, (state, action) => {
        state.globalLoader = false;
      });
  },
});

export const {
  setFormType,
  setUpdateAllFormField,
  setClearListData,
  setSelectedCardUpdate,
  setUpdateFormField,
  setUpdateDataDoctorPage,
  setClearAllFormData,
  setUpdateGlobalLoader,
  setUpdatePushList,
  setUpdateFormularyData,
  setDrugCostData,
  setSelectedDrugs,
  setDrugCostPlan,
  setDrugCostYear,
  setClearDrugCostData,
  setClearDrugList,
  setClearFormularyData,
} = filterSlice.actions;

export default filterSlice.reducer;
